import { BID_TITLES, BoardBid } from '../board-bid';
import { BoardFooter } from '../board-footer';
import { LotStatus } from '../lot-status';
import styles from './toteboard.module.scss';

import { IToteBoardProps } from './types';

export function ToteBoard({ lotBidder, bidAmount, lotNumber, lotStatus, isOnlineBidSubmitted }: IToteBoardProps) {
  const animationClass = isOnlineBidSubmitted ? styles['blinker-animation'] : '';

  return (
    <div className={styles['board-main-holder']}>
      <div className={styles['tote-board-main']}>
        <LotStatus status={lotStatus} />
        <BoardBid
          animationClass={animationClass}
          bidTitle={isOnlineBidSubmitted ? BID_TITLES.SUBMITTED_BID : BID_TITLES.ACCEPTED_BID}
          bidAmount={bidAmount}
        />
        <BoardFooter animationClass={animationClass} lotNumber={lotNumber} lotBidder={lotBidder} />
      </div>
    </div>
  );
}
